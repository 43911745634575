import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';

function LanguageSelector() {

  const countries = [
    {
      code: "cy",
      name: "Cymraeg",
      country_code: "cy"
    },
    {
      code: "en",
      name: "English",
      country_code: "gb"
    },
  ];

  const { i18n } = useTranslation();

  return (
    <>
    {countries.map(lng => {
      if (lng.code !== i18n.language) return (
        <div key={lng.code}>
            <Button
              onClick={() => i18n.changeLanguage(lng.code)}
              disabled={i18n.language === lng.code}
            >
              <span>{lng.name}</span>
            </Button>
        </div>
    )})}
    </>
  );
}

export default LanguageSelector;