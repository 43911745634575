import useTranslation from "i18next";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CalendarIcon from '../icons/calendar-x.svg';
import LocationIcon from '../icons/geo-alt.svg';
import MoneyIcon from '../icons/cash.svg';

function Job({job}) {
  const {t} = useTranslation;
  var salary = "";

  if(job.minimumSalary && job.maximumSalary) {
    salary = `£${job.minimumSalary} - £${job.maximumSalary}`;
  } else if (job.minimumSalary && !job.maximumSalary) {
    salary = `£${job.minimumSalary}`;
  } else if (!job.minimumSalary && job.maximumSalary) {
    salary = `£${job.maximumSalary}`;
  }

  return (
    <Container className="job">
        <Row>
            <Col>
                <h2><a href={job.jobUrl} rel="noreferrer" target="_blank">{job.jobTitle}</a></h2>
            </Col>
        </Row>
        <Row>
              <Col>
                {job.date && (
                  <>
                    <strong>{t('date')}: </strong> {job.date}<br />
                 </>
                )}
                {job.employerName && (
                 <>
                   <strong>{t('employer name')}: </strong> {job.employerName}<br />
                 </>
                )}
                {job.expirationDate && (
                  <>
                    <img src={CalendarIcon} alt={t('expiry date')}/> {job.expirationDate}
                  </>
                )}
              </Col>
              <Col>
                {job.locationName && (
                  <>
                    <img src={LocationIcon} alt={t('location')}/> {job.locationName}<br />
                  </>
                )}
                {salary !== "" && (
                  <>
                    <img src={MoneyIcon} alt={t('salary')}/>{salary}<br />
                  </>
                )}
              </Col>
              <Col>
                  <p>{job.jobDescription}</p>
              </Col>
          </Row>
    </Container>
  );
}

export default Job;
