import './App.scss';
import { useState, useEffect } from "react";
import Search from './components/Search';
import Job from './components/Job';
import LanguageSelector from "./components/LanguageSelector";
import { searchJobs } from "./requests.js";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { clear } from '@testing-library/user-event/dist/clear.js';

function App() {

  const {t, i18n} = useTranslation();
  const [jobList, setJobList] = useState([]);
  
  const [formData, setFormData] = useState({
    keywords: "",
    location: "",
    distance: 0
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setJobList([]);
    searchJobs(formData, i18n.language).then((data) => {
      if(data.length === 1) {
        setJobList([data]);
      } else {
        setJobList(data);
      }
    });
  }

  const clearSearch = () => {
    setFormData({
      keywords: "",
      location: "",
      distance: 0
    });
    setJobList([]);
  }

  i18n.on('languageChanged', function(lng) {clearSearch()});

  useEffect(() => {
    document.title = t('job search');
  }, [t]);

  return (
    <div className="App">
      <Container as="header">
        <Row className="align-items-end justify-content-between">
          <Col>
            <h1>{t('job search')}</h1>
          </Col>
          <Col style={{flex: '0 1'}} className="d-flex justify-content-end">
            <LanguageSelector />
          </Col>
        </Row>
      </Container>
      <Search formData={formData} setFormData={setFormData} onSubmit={handleSubmit}/>
      <main>
        <>
        {jobList.length > 0 && jobList.map(job => {
          return (
            <Job key={job.employerName + job.jobTitle} job={job}></Job>
          )
        })}
        </>
        {jobList.length === 0 && 
          (<Container>
            <p>No jobs found. Please try again.</p>
          </Container>)
        }
      </main>
    </div>
  );
}

export default App;
