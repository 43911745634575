const searchJobs = async (formData, language) => {

    const url = "https://reed-job-search-api-20240808171050564.azurewebsites.net/api/jobsearch";
    const query = "?keywords=" + formData.keywords + "&location=" + formData.location + "&distance=" + formData.distance + "&lang=" + language;

    try {
        const response = await fetch(url+query);
        if (!response.ok) {
            throw new Error(`Response status: ${response.status}`)
        }
        const json = await response.json();

        return json;
    } catch(error) {
        console.log(error.message);
    }
}

module.exports = {
 searchJobs
};