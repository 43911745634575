import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function Search({formData, setFormData, onSubmit}) {
  const {t} = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="search">
      <Container as="form" action="" onSubmit={onSubmit}>
          <Row className="align-items-end justify-content-between">
            <Col className="">
              <Form.Label htmlFor="search-keywords">{t('job title')}</Form.Label>
              <Form.Control id="search-keywords" type="text" name="keywords" value={formData.keywords} onChange={handleChange} placeholder={t('job title')}/>
            </Col>
            <Col>
              <Form.Label htmlFor="search-location">{t('location')}</Form.Label>
              <Form.Control id="search-location" type="text" name="location" value={formData.location} onChange={handleChange} placeholder={t('location')} />
            </Col>
            <Col>
              <Form.Label htmlFor="search-distance">{t('distance')}</Form.Label>
              <Form.Control id="search-distance" type="number" name="distance" value={formData.distance} onChange={handleChange} placeholder="0" />
            </Col>
            <Col style={{flex: '0 1'}}>
              <Button className="btn-secondary" type="submit">{t('search')}</Button>
            </Col>
          </Row>
      </Container>
    </div>
  );
}

export default Search;
